<template>
  <div>
    <div class="conMsg">
      <div class="return" v-if="shopId" @click="back">
        <img class="backImg" src="@/assets/images/back.png" alt />
      </div>
      <div class="return" v-if="pageName" @click="backList">
        <img class="backImg" src="@/assets/images/back.png" alt />
      </div>
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
        <div>
          <el-button
            v-if="detail.selection_results > 0  && detail.telephone_selection == 1 && !userInfo.shop_id"
            class="mb10"
            type="primary"
            size="mini"
            @click="dialogVisible = true"
          >技术评级</el-button>
          <el-button
            v-if="detail.selection_results == 1 && detail.telephone_selection==0 && !userInfo.shop_id"
            class="mb10"
            type="primary"
            size="mini"
            @click="dialogTel = true"
          >电话评审</el-button>
          <el-button
            v-if="(detail.selection_results == 0 || detail.telephone_selection==2) && detail.sq_ok == 1 "
            class="mb10"
            type="primary"
            size="mini"
            @click="applyforType = true"
          >申请评级</el-button>
          <!-- <el-button
            v-if="(detail.selection_results == 2 || detail.selection_results==3) && !userInfo.shop_id && detail.is_collection==0"
            class="mb10"
            type="primary"
            size="mini"
            @click="hint('将此案例加入案例库','加案例库')"
          >加入案例库</el-button>-->
        </div>
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item>
          <template slot="label">客户名称</template>
          {{detail.customer_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">客户性别</template>
          {{detail.sex == 1?'男':'女'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">评选位置</template>
          {{detail.position_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">疤痕原因</template>
          {{detail.reason_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">疤痕类型</template>
          {{detail.type_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">护理师</template>
          {{detail.nurse_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">门店</template>
          {{detail.shop_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">归属市场</template>
          {{detail.market_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">是否毕业</template>
          {{detail.graduation_type_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">申请类型</template>
          {{detail.sq_type_name?detail.sq_type_name:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">申请时间</template>
          <span>{{detail.sp_excellent_time?detail.sp_excellent_time:'暂无'}}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">电话服务评比</template>
          <span>{{detail.case_results_name?detail.case_results_name:'暂无'}}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">电话效果评比</template>
          <span>{{detail.effect_results_name?detail.effect_results_name:'暂无'}}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">电话通过评比</template>
          <span>{{detail.telephone_selection_name?detail.telephone_selection_name:'暂无'}}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">电话回访记录</template>
          <span>{{detail.return_visit_record?detail.return_visit_record:'暂无'}}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">技术评选结果</template>
          <span v-if="detail.selection_results2 == 1">通过</span>
          <span v-else>未通过</span>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">是否加入案例库</template>
          {{detail.is_collection==0?'未加入':'已加入'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">加入案例库的时间</template>
          {{detail.collection_time?detail.collection_time:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">审理人</template>
          {{detail.reviewer_name?detail.reviewer_name:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">审核时间</template>
          {{detail.tg_excellent_time?detail.tg_excellent_time:'暂无'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">评选结果内容</template>
          {{detail.two_content?detail.two_content:'暂无'}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">客户电话</template>
          {{detail.telephone}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">最新申请时间</template>
          {{detail.shenqing_time}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">申请总次数</template>
          {{detail.shenqingnum}}
        </el-descriptions-item>
        
        <el-descriptions-item>
          <template slot="label">申请部位</template>
          {{detail.shenqing_position}}
        </el-descriptions-item>
        
        <el-descriptions-item>
          <template slot="label">申请疤痕类型	</template>
          {{detail.shenqing_type}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">申请疤痕成因</template>
          {{detail.shenqing_reason}}
        </el-descriptions-item>

      </el-descriptions>
    </div>

    <div class="conMsg">
      <!-- v-if="detail.is_collection == 0" -->
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />案例评选记录
        </div>
      </div>

      <el-row>
        <el-col :span="24">
          <el-table
            :data="historyList"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="申请时间" prop="sp_excellent_time" width="150" />
            
            <ElTableColumn label="评选位置" prop="position_name" />
            <ElTableColumn label="疤痕原因" prop="reason_name" />
            <ElTableColumn label="疤痕类型" prop="type_name" />
            <ElTableColumn label="门店" prop="shop_name" />
            <!-- <ElTableColumn label="归属市场" prop="market_name" /> -->
            <ElTableColumn label="评选结果">
              <template slot-scope="{ row }">
                
                <span v-if="row.selection_results == 2">优秀案例</span>
                <span v-if="row.selection_results == 3">满意案例</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="审理人" prop="reviewer_name" />
            <ElTableColumn label="审核时间" prop="tg_excellent_time" width="140" />
            <ElTableColumn label="操作" fixed="right" width="120">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('detail',row.id)">查看</span>
              </template>
            </ElTableColumn>
          </el-table>
          
        </el-col>
      </el-row>
      
    </div>

    <div class="conMsg">
      <!-- v-if="detail.is_collection == 0" -->
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />案例服务记录
        </div>
      </div>
      <activity
        handle-type="order"
        :activity-list="follow"
        handleType="case"
        @followAdd="followAdd"
      />
    </div>
    <!-- 电话审核 -->
    <el-dialog title="申请评级" :visible.sync="applyforType" :close-on-click-modal="false" width="40%">
      <el-form
        ref="applyforDetail"
        label-position="right"
        label-width="180px"
        :rules="applyRules"
        :model="applyforDetail"
      >
        <el-form-item label="申请评级">
          <el-select
            v-model="applyforDetail.applyforCase"
            class="w300"
            clearable
            placeholder="请选择申请评级"
          >
            <el-option
              v-for="item in options.applyforCase"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footerBtn">
        <el-button
          type="primary"
          size="mini"
          class="mainBtn"
          @click="subApplyFor('')"
        >完成，提交</el-button>
      </div>
    </el-dialog>
    <!-- 电话审核 -->
    <el-dialog title="电话审核" :visible.sync="dialogTel" :close-on-click-modal="false" width="40%">
      <el-form
        ref="telDetail"
        label-position="right"
        label-width="180px"
        :rules="telRules"
        :model="telDetail"
      >
        <el-form-item label="服务评比">
          <el-select
            v-model="telDetail.case_results"
            class="w300"
            clearable
            placeholder="请选择服务评比结果"
          >
            <el-option
              v-for="item in options.case_results"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="效果评比">
          <el-select
            v-model="telDetail.effect_results"
            class="w300"
            clearable
            placeholder="请选择效果评比结果"
          >
            <el-option
              v-for="item in options.effect_results"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="通过评比">
          <el-select
            v-model="telDetail.telephone_selection"
            class="w300"
            clearable
            placeholder="请选择效果评比结果"
          >
            <el-option
              v-for="item in options.telephone_selection"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="回访记录">
          <el-input class="w300" type="textarea" v-model="telDetail.return_visit_record"></el-input>
        </el-form-item>

        <div class="footerBtn">
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitTel('telDetail')"
          >完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 技术审核 -->
    <el-dialog
      title="技术审核"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form
        ref="rateDetail"
        label-position="right"
        label-width="160px"
        :rules="rules"
        size="mini"
        :model="rateDetail"
      >
        <div class="disTil">评选资料：</div>
        <div class="disRow">
          <el-form-item label="评选位置:" prop="position_id">
            <el-select
              v-model="rateDetail.position_id"
              class="w200"
              clearable
              placeholder="请选择评选位置"
            >
              <el-option
                v-for="item in options.position_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="形成原因:" prop="reason_id">
            <el-select v-model="rateDetail.reason_id" class="w200" clearable placeholder="请选择形成原因">
              <el-option
                v-for="item in options.reason_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="疤痕类型:" prop="type_id">
          <el-select v-model="rateDetail.type_id" class="w200" clearable placeholder="请选择疤痕类型">
            <el-option
              v-for="item in options.type_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="disTil">电话评审内容：</div>
        <div class="disRow">
          <el-form-item label="服务评比" prop="case_results">
            <el-select
              v-model="rateDetail.case_results"
              class="w200"
              clearable
              placeholder="请选择服务评比结果"
            >
              <el-option
                v-for="item in options.case_results"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="效果评比" prop="effect_results">
            <el-select
              v-model="rateDetail.effect_results"
              class="w200"
              clearable
              placeholder="请选择效果评比结果"
            >
              <el-option
                v-for="item in options.effect_results"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>

        <el-form-item label="通过评比" prop="telephone_selection">
          <el-select
            v-model="rateDetail.telephone_selection"
            class="w200"
            clearable
            placeholder="请选择效果评比结果"
          >
            <el-option
              v-for="item in options.telephone_selection"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="回访记录">
          <el-input type="textarea" v-model="rateDetail.return_visit_record" maxlength="50"></el-input>
        </el-form-item>
        <div class="disTil">评选结果：</div>
        <div class="disRow">
          <el-form-item label="评选结果">
            <el-radio-group v-model="rateDetail.selection_results2">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="2">不通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否加入案例库">
            <el-radio-group v-model="rateDetail.is_collection">
              <el-radio :label="0">不加人</el-radio>
              <el-radio :label="1">加入</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="评选结果内容">
          <el-input type="textarea" v-model="rateDetail.two_content" maxlength="50"></el-input>
        </el-form-item>

        <div class="footerBtn">
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('rateDetail')"
          >完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import activity from "@/components/Activity";
import { mapGetters } from "vuex";
import { ssettingList } from "@/api/client";
import {
  caseDetail,
  addCaseLog,
  psCase,
  psTj,
  addpsCase,
  jspsCase,
  caseHistoryLog
} from "@/api/case";
class applyforDetail {
  applyforCase = "";
}
class TelDetail {
  case_results = "";
  effect_results = "";
  telephone_selection = "";
  return_visit_record = "";
}
class RateDetail {
  selection_results2 = 1;
  is_collection = 0;
  two_content = "";
  position_id = "";
  reason_id = "";
  type_id = "";
  case_results = "";
  effect_results = "";
  telephone_selection = "";
  return_visit_record = "";
}
class applyRules{
  applyforCase = [
    { required: true, message: "请选择申请评级", trigger: "change" }
  ];
}
class TelRules {
  case_results = [
    { required: true, message: "请选择服务评比", trigger: "change" }
  ];
  effect_results = [
    { required: true, message: "请选择效果评比", trigger: "change" }
  ];

  telephone_selection = [
    { required: true, message: "请选择效通过评比", trigger: "change" }
  ];
  return_visit_record = [
    { required: true, message: "请填写回访记录", trigger: "blur" }
  ];
}
class Rules {
  position_id = [
    { required: true, message: "请选择评选位置", trigger: "change" }
  ];
  reason_id = [
    { required: true, message: "请选择形成原因", trigger: "change" }
  ];
  type_id = [{ required: true, message: "请选择疤痕类型", trigger: "change" }];
  case_results = [
    { required: true, message: "请选择服务评比", trigger: "change" }
  ];
  effect_results = [
    { required: true, message: "请选择效果评比", trigger: "change" }
  ];

  telephone_selection = [
    { required: true, message: "请选择效通过评比", trigger: "change" }
  ];
  return_visit_record = [
    { required: true, message: "请填写回访记录", trigger: "blur" }
  ];
}
class Options {
  applyforCase = [
    { id: 1, name: "优秀案例"},
    { id: 2, name: "满意案例"},
  ];
  case_results = [
    { id: 1, name: "服务满意" },
    { id: 2, name: "服务不满意" },
    { id: 3, name: "电话不通过" },
    { id: 4, name: "电话未接听" }
  ];
  effect_results = [
    { id: 1, name: "效果不满意" },
    { id: 2, name: "效果一般满意" },
    { id: 3, name: "效果满意" },
    { id: 4, name: "电话未接听" }
  ];
  telephone_selection = [
    { id: 1, name: "通过" },
    { id: 2, name: "不通过" }
  ];
  position_id = [];
  reason_id = [];
  type_id = [];
}
export default {
  components: {
    activity
  },
  data() {
    return {
      applyforDetail: new applyforDetail(),
      applyRules: new applyRules(),
      telDetail: new TelDetail(),
      telRules: new TelRules(),
      rateDetail: new RateDetail(),
      rules: new Rules(),
      options: new Options(),
      detail: {},
      follow: [], // 跟进记录
      dialogVisible: false,
      dialogTel: false,
      applyforType: false,
      colors: ["#99A9BF", "#33bea7df", "#33bea7"],
      followDetail: "",
      shopId: "",
      pageName:'',
      historyList:[],
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getHistory();
    this.getOptions();
    this.getDetail();
    this.shopId = this.$route.query.shopId;
    this.pageName = this.$route.query.pageName;
  },
  methods: {
    getHistory(){
      caseHistoryLog({ case_id: this.$route.query.id }).then(res => {
        
        this.historyList = res.data;
      })
    },
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.type_id.push(item);
          } else if (item.category_id == 2) {
            that.options.position_id.push(item);
          } else if (item.category_id == 3) {
            that.options.reason_id.push(item);
          }
        });
      });
    },
    // 获取详情信息
    getDetail() {
      caseDetail({ case_id: this.$route.query.id }).then(res => {
        this.detail = res.data;
        this.follow = res.data.list;
        this.rateDetail.selection_results2 = res.data.selection_results2;
        this.rateDetail.is_collection = res.data.is_collection;
        this.rateDetail.two_content = res.data.two_content;
        this.rateDetail.position_id = res.data.position_id;
        this.rateDetail.reason_id = res.data.reason_id;
        this.rateDetail.type_id = res.data.type_id;
        this.rateDetail.case_results = res.data.case_results;
        this.rateDetail.effect_results = res.data.effect_results;
        this.rateDetail.telephone_selection = res.data.telephone_selection;
        this.rateDetail.return_visit_record = res.data.return_visit_record;
      });
    },
    // 添加跟进
    followAdd(detail) {
      this.followDetail = detail;
      this.hint("添加跟进", "跟进");
    },
    // 添加跟进
    addFollowAdd() {
      const { content, file_path } = this.followDetail;
      addCaseLog({
        case_id: this.$route.query.id,
        content: content,
        iamges: file_path
      }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.getDetail();
      });
    },
    // 申请评级
    subApplyFor() {
      console.log(this.applyforDetail.applyforCase);

      this.applyCase(this.applyforDetail.applyforCase);
      // this.$confirm("请选择申请案例类型", "提交申请", {
      //   confirmButtonText: "优秀案例",
      //   cancelButtonText: "满意案例",
      //   type: "warning"
      // })
      //   .then(() => {
      //     this.applyCase(1);
      //   })
      //   .catch(() => {
      //     this.applyCase(2);
      //   });
    },
    // 案例申请
    applyCase(type) {
      psTj({ case_id: this.$route.query.id, type: type }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.applyforType = false;
        this.getDetail();
      });
    },
    // 电话评审提交
    submitTel(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("为此案例电话评审", "电话评级");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 技术评审提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("为此案例评级", "技术评级");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "跟进") {
            this.addFollowAdd();
          } else {
            this.confirm(type);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确认提交
    confirm(type) {
      if (type == "技术评级") {
        jspsCase({ ...this.rateDetail, case_id: this.$route.query.id }).then(
          res => {
            this.$message({
              type: "success",
              message: res.msg
            });
            this.dialogVisible = false;
            this.getDetail();
          }
        );
      } else if (type == "加案例库") {
        addpsCase({ case_id: this.$route.query.id }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.getDetail();
        });
      } else {
        psCase({ ...this.telDetail, case_id: this.$route.query.id }).then(
          res => {
            this.$message({
              type: "success",
              message: res.msg
            });
            this.dialogTel = false;
            this.getDetail();
          }
        );
      }
    },
    // 返回
    back() {
      const query = { id: this.$route.query.shopId, activeName: "fourth" };
      this.$router.push({ name: "店铺详情", query });
    },
    // 返回案例列表
    backList() {
      const query = { search: this.$route.query.search?this.$route.query.search:'',page:this.$route.query.page?this.$route.query.page:''};
      this.$router.push({ name: this.$route.query.pageName, query });
    },

    // 点击详情
    tapBtn(type, id) {
      switch (type) {
        case "detail":
          const query = {
            id: id,
            pageName: "案例列表",
            search: this.search,
            page: this.page
          };
          //this.$router.push({ name: "案例详情", query });
		  
		      let route = this.$router.resolve({ name: "案例详情", query });
		      window.open(route.href, '_blank');
          // this.$router.push('./caseDetail?id=' + id)
          break;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .return {
    position: fixed;
    right: 20px;
    top: 71px;
    z-index: 999;
    .backImg {
      margin-right: 14px;
      width: 32px;
      height: 100%;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
  .submit {
    text-align: center;
  }
}
.mainBtn {
  margin: 40px 0 20px 20px;
}
.footerBtn {
  text-align: right;
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background: #f9fafc;
}
.disRow {
  display: flex;
  .el-form-item {
    width: 50%;
  }
}
.disTil {
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
